import axios from 'axios';

const fetchDocument = async (entityName, path) => {
  try {
    const apiUrl =
      process.env.REACT_APP_ENV === 'test'
        ? 'https://documentapi.rovelabs.com/test/get_document'
        : 'https://documentapi.rovelabs.com/prod/get_document';

    const res = await axios.get(apiUrl, {
      params: { entityName: entityName, key: path }
    });

    console.log(res);
    const response = await fetch(res.data.url);
    const arrayBuffer = await response.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    return buffer; 
  } catch (error) {
    console.error(error);
    throw error
    //return null
  }
};

export default fetchDocument;
